<script>

    import appConfig from "@/app.config.json";
    import helper from "@/assets/helpers/helper";

    export default {
        page: {
            title: "Profile Badge",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'ProfilBadge',
        data() {
            return {
                title: "Profile Badge",
                user:{},
                badge:{},
            };
        },
        created(){
            this.loadBadge()
        },
        methods: {
            async loadBadge() {
                let data = await helper.getAll('users/'+this.$route.params.user_id+'/badges/'+this.$route.params.badge_id);
                this.user = data.user
                this.badge = data.badge
            },
        }
    };
</script>

<template>

        <div class="row  mx-0 pt-3" >
            <div class="col-xl-12  pb-5">
                <img src="@/assets/images/logo-light.png" alt height="50" />
            </div>
                <div class="col-xl-8 row mx-auto" >
                    <div class="card col-xl-8 mx-auto" v-if="user.username">
                        <h1 class="py-4  text-center link"   @click="$router.push({name:'ProfilUser',params:{id:user.user_id}})">{{user.username}}</h1>
                        <div class="group-details pb-3 d-none d-md-block">
                            <ul class="d-flex align-items-center justify-content-between list-inline text-center m-0 p-0">
                                <li class="pl-3 pr-3">
                                    <p class="mb-2">{{$t('profil_user.score')}}</p>
                                    <h6>{{user.score}}</h6>
                                </li>
                                <li class="pl-3 pr-3">
                                    <p class="mb-2">{{$t('profil_user.level')}}</p>
                                    <h6>{{user.level}}</h6>
                                </li>
                                <li class="pl-3 pr-3">
                                    <p class="mb-2">{{$t('profil_user.country')}}</p>
                                    <h6>{{user.country}}</h6>
                                </li>

                            </ul>
                        </div>

                        <p class="glow text-center">{{$t('badges.msg')}}</p>
                    </div>
                    <div class="card-body col-xl-8 mx-auto text-center">

                                <b-avatar class="mb-4" :src="badge.avatar" size="7rem"></b-avatar>
                               <h4 class="text-dark mb-2">{{badge.title}}</h4>
                                <p class="text-muted mb-4">{{badge.description}}</p>




                    </div>
                </div>

        </div>

</template>
<style>
    .card{
        background-color: inherit;
    }
    .glow {
        font-size: 30px;
        color: #ECBD34;
        text-align: center;
    }

    @-webkit-keyframes glow {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ECBD34, 0 0 40px #ECBD34, 0 0 50px #ECBD34, 0 0 60px #ECBD34, 0 0 70px #ECBD34;
        }

        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ECBD20, 0 0 40px #ECBD30, 0 0 50px #ECBD34, 0 0 60px #ECBD34, 0 0 70px #ECBD34, 0 0 80px #ECBD34;
        }
    }
</style>